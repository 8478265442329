import React from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FORM_DATE_FORMAT } from '../../config/constant';
dayjs.extend(customParseFormat);


export default function DatePickerInput({...props}) {
  return (
    <DatePicker 
      {...props}
      format={FORM_DATE_FORMAT} 
      allowClear
      style={props.fullWidth ? {width: '100%'} : {}}
      inputReadOnly
    />
  )
}

import React from 'react'
import AuthRouting from './AuthRouting'
import AdminRouting from './AdminRouting'
import { useSelector } from 'react-redux'

export default function Routing() {
  const {token} = useSelector(state => state.auth);

  return (
    !token ? <AuthRouting /> : <AdminRouting />
  )
}

import React, { useContext } from 'react'
import { LoginFormContext } from '../../context/LoginFormContext';
import Welcome from './Welcome';
import Authenticate from './Authenticate';

export default function LoginForm() {
    const {currentStep} = useContext(LoginFormContext);

    return (
      <div className='card login-card'>
        { currentStep === 1 && <Welcome />}
        { currentStep === 2 && <Authenticate /> }
      </div>
    )
}

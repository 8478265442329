import { toast } from "react-toastify";
import { ErrorToast, InfoToast, SuccessToast, WarnToast } from "../assets/images";

export const tostS = (msg) => {
    var toastId = null;
    var customToastId = 1234;
    if (toast.isActive(toastId)) {
        toastId = toast.success(msg);
    }

    toast.success(msg, {
        toastId: customToastId,
        icon: <img src={SuccessToast} alt="success" />,
    });
};

export const tostE = (msg) => {
    var toastId = null;
    var customToastId = 5678;
    if (toast.isActive(toastId)) {
        toastId = toast.error(msg);
    }

    toast.error(msg, {
        toastId: customToastId,
        icon : <img src={ErrorToast} alt="error" />
    });
};

export const tostW = (msg) => {
    // console.log(data)
    var toastId = null;
    var customToastId = 9012;
    if (toast.isActive(toastId)) {
        toastId = toast.warn(msg);
    }

    toast.warn(msg, {
        toastId: customToastId,
        icon : <img src={WarnToast} alt="warn" /> 
    });
};

export const tostI = (msg) => {
    // console.log(data)
    var toastId = null;
    var customToastId = 3456;
    if (toast.isActive(toastId)) {
        toastId = toast.info(msg);
    }

    toast.info(msg, {
        toastId: customToastId,
        icon : <img src={InfoToast} alt="info" /> 
    });
};

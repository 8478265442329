const initialAuthState = {
    token: null, // API token
    user: null,  // User data
  };
  
  export function authReducer(state = initialAuthState, action) {
    switch (action.type) {
      case 'SET_AUTH_DATA':
        return {
          ...state,
          token: action.payload.token,
          user: action.payload.user,
        };
      case 'CLEAR_AUTH_DATA':
        return {
          ...state,
          token: null,
          user: null,
        };
      default:
        return state;
    }
  }
import axios from "axios";
import { API_BASE_URL } from "../config/constant";
import { store } from '../redux/store';
import { clearAuthData } from '../redux/actions/authActions';

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

axiosInstance.interceptors.request.use((config) => {
    const { token } = store.getState().auth;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            store.dispatch(clearAuthData());
        }
        return Promise.reject(error);
    }
);

import { Navigate, useRoutes } from 'react-router-dom'
import DashboardLayout from '../layout/DashboardLayout'
import { DashBoardPage, EventPage, OrganizersPage } from '../screens/app'

export default function AdminRouting() {
  return useRoutes([
    {
      path: "/app", 
      element : <DashboardLayout />,
      children : [
        {path: "dashboard", element : <DashBoardPage />},
        {path: "events/:page?/:id?/:tab?", element : <EventPage />},
        {path: "organizers", element : <OrganizersPage />},
        { path: "*", element : <Navigate to="/app/dashboard" replace /> }
      ]
    },
    {path: '*' , element : <Navigate to="/app/dashboard" replace /> }
  ])
}

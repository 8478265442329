import React, { useEffect, useState } from 'react'
import { BreadCrumbs, DatePickerInput, Label, Modal } from '../../../components/ui';
import { GetEventListService, CreateEventService } from '../../../apis/events';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Input, Switch } from 'antd';
import dayjs from 'dayjs';
import { EVENT_NAME_REGEX, HASTAG_REGEX } from '../../../config/constant';
import { useNavigate } from 'react-router-dom';
import EventListSkeleton from '../../../components/common/EventListSkeleton';
import { EventCard, NoEventComponent } from '../../../components/event';
import { tostE, tostS } from '../../../config/toastConfig';


export default function EventListing() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const getEvents = async () => {
    setLoading(true);
    try {
      const res = await GetEventListService();
      if (res.status === 200 && res.data.success) {
        setData(res.data.event_list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  const CreateEventSchema = Yup.object().shape({
    event_name: Yup.string().required("Event name is required").matches(EVENT_NAME_REGEX, "Event name is invalid"),
    hastag: Yup.string().required("Hashtag is required").matches(HASTAG_REGEX, "Hashtag is invalid"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date().required("End date is required"),
  });

  const formik = useFormik({
    initialValues: {
      event_name: "",
      hastag: "",
      start_date: "",
      end_date: "",
      gallery_btn: true
    },
    validationSchema: CreateEventSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {

      let body = {
        event_name: values.event_name,
        hastag: values.hastag,
        start_date: (values.start_date).format('YYYY-MM-DD'),
        end_date: (values.end_date).format('YYYY-MM-DD'),
        gallery_btn: values.gallery_btn ? 1 : 0
      }
      try {
        let res = await CreateEventService(body);
        if (res.status === 200 && res.data.success) {
          tostS(res.data.message);
          setShowModal(false);
          getEvents();
          resetForm();
        }
      } catch (error) {
        tostE(error.response.data.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  const { values, setValues, errors, touched, getFieldProps, handleSubmit, resetForm, isSubmitting } = formik;

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <BreadCrumbs
          links={[
            { name: 'Home', link: '/', icon: 'fa-house' },
            { name: 'Events', link: '/app/events', icon: 'fa-calendar' },
          ]}
        />
        <Button type='primary' onClick={() => setShowModal(true)}>Create New</Button>
      </div>
      <div className='main-content'>
        {!loading ? ((data.length) > 0 ? data.map((item, index) => (
          <EventCard 
            key={index} 
            data={item} 
            manageEvent={(data) => {navigate(`/app/events/manage/${data.id}/overview`)}}
          />
        )) 
        :(<>
          <NoEventComponent />
        </>)) : (<EventListSkeleton />)}
      </div>
      <Modal
        modalTitle={"Create Event"}
        open={showModal}
        confirmLoading={isSubmitting}
        handleConfirm={() => {
          handleSubmit();
        }}
        handleCancel={() => {
          resetForm();
          setShowModal(false);
        }}
        okText={"Create Event"}
      >
        <FormikProvider value={formik}>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Label
              label='Event Name'
              error={touched.event_name && errors.event_name}
              helperText={touched.event_name && errors.event_name ? errors.event_name : ''}
              required={true}
            >
              <Input
                placeholder="Enter event name"
                {...getFieldProps('event_name')}
              />
            </Label>
            <Label
              label='Hashtag'
              error={touched.hastag && errors.hastag}
              helperText={touched.hastag && errors.hastag ? errors.hastag : ''}
              required={true}
            >
              <Input
                placeholder="Enter hashtag(#hashtag)"
                {...getFieldProps('hastag')}
              />
            </Label>
            <Label
              label='Start Date'
              error={touched.start_date && errors.start_date}
              helperText={touched.start_date && errors.start_date ? errors.start_date : ''}
              required={true}
            >
              <DatePickerInput
                placeholder="Select Start Date"
                showNow={false}
                value={values.start_date}
                onChange={(date) => {
                  setValues({ ...values, start_date: date })
                }}
                minDate={dayjs(new Date())}
                fullWidth={true}
              />
            </Label>
            <Label
              label='End Date'
              error={touched.end_date && errors.end_date}
              helperText={touched.end_date && errors.end_date ? errors.end_date : ''}
              required={true}
            >
              <DatePickerInput
                placeholder="Select End Date"
                showNow={false}
                value={values.end_date}
                onChange={(date) => {
                  setValues({ ...values, end_date: date })
                }}
                minDate={dayjs(values.start_date)}
                fullWidth={true}
                disabled={values.start_date ? false : true}
              />
            </Label>
            <Label
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              label='Gallery Button'
              error={touched.gallery_btn && errors.gallery_btn}
              helperText={touched.gallery_btn && errors.gallery_btn ? errors.gallery_btn : ''}
            >
              <Switch
                checked={values.gallery_btn}
                onChange={(value) => {
                  setValues({ ...values, gallery_btn: value })
                }}
                size='small'
              />
            </Label>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  )
}

import React from 'react'
import { Form } from 'antd';

export default function Label({
    error = false,
    helperText= "",
    label= "",
    required=false,
    children,
    ...props
}) {
  return (
    <Form.Item
        label={<span>{label} {required && <span style={{ color: 'red' }}>*</span>}</span>}
        validateStatus={error ? 'error' : ''}
        help={helperText}
        {...props}
    >
        {children}
    </Form.Item>
  )
}

import React from 'react'
import EventListing from './EventListing'
import ManageEvents from './ManageEvents'
import { useParams } from 'react-router-dom'

export default function EventPage() {
  const {page} = useParams();

  const renderPage = (page) => {
    switch (page) {
      case 'manage':
        return <ManageEvents />
      default:
        return <EventListing />
    }
  }

  return (
    <>
    {renderPage(page)}
    </>
  )
}

import React from 'react'
import { formatEventDate } from '../../utils/commonFunctions'
import { Button } from 'antd'
import { EventStatusRender } from '../common/StatusRender'
import { DummyEvent } from '../../assets/images'

export default function EventCard({ data, manageEvent = () => { } }) {
  return (
    <div className='event-card'>
      <div className='left-content'>
        <div className='event-logo-info'>
          <img src={DummyEvent} alt="" />
          <p className='event-date'>{formatEventDate(data.start_date, data.end_date)}</p>
        </div>
        <div className='event-description'>
          <h3>{data.event_name}</h3>
          <p>{data.hastag}</p>
          <p><i class="fa-solid fa-user-group"></i> {data.users_count} Guests</p>
        </div>
      </div>
      <div className='right-content'>
        <EventStatusRender value={data.status} />
        <Button type='default' icon={<i class="fa-solid fa-arrow-up-right"></i>} onClick={() => { manageEvent(data) }}>
          Manage
        </Button>
      </div>
    </div>
  )
}

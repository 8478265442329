import React, { useEffect, useState } from 'react'
import { Table } from '../../../../components/ui'
import { useParams } from 'react-router-dom';
import { GetEventPhotosService } from '../../../../apis/events';
import { Avatar, Button } from 'antd';

export default function EventPhotos() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [view, SetView] = useState('List');
    const [loading, setLoading] = useState(false);

    const getEventPhotos = async () => {
        setLoading(true);
        try {
            const res = await GetEventPhotosService({ event_id: id });
            if (res.status === 200 && res.data.success) {
                setData(res.data.photo_list);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getEventPhotos();
    }, []) // eslint-disable-line

    const columns = [
        {
            title: 'Photo',
            dataIndex: 'image_url',
            key: 'name',
            render: (text, record) => (
                <Avatar shape="square" size={64} src={text} />
            )
        },
        {
            title: 'Uploaded By',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        },

    ]

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h5>{view} View</h5>
                <div>
                <Button onClick={() => {SetView("List")}} title='List View' shape="square" icon={<i className="fa-solid fa-list" />} style={{ marginRight: 10 }} />
                <Button onClick={() => {SetView("Grid")}} title='Grid View' shape="square" icon={<i className="fa-solid fa-grip" />} />
                </div>
            </div>
            {view === "List" && <Table
                columns={columns}
                data={data}
                loading={loading}
            />}
            {view === "Grid" && 
            <>
                <div className='row'>
                    {data.map((item, index) => (
                        <div className='col-lg-2 col-md-3 col-xs-3 p-1' key={index}>
                            <div className='grid-item' style={{ backgroundImage: `url(${item.image_url})` }}>
                            </div>
                        </div>
                    ))}
                </div>
            </>
            }
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { GetEventUsersService } from '../../../../apis/events';
import { useParams } from 'react-router-dom';
import { Table } from '../../../../components/ui';

export default function EventUsers() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEventUsers = async () => {
        setLoading(true);
        try {
            const res = await GetEventUsersService({ event_id: id });
            if (res.status === 200 && res.data.success) {
                setData(res.data.userlist);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getEventUsers();
    }, []) // eslint-disable-line

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ]

    return (
        <>
            <Table
                columns={columns}
                data={data}
                loading={loading}
            />
        </>
    )
}

import { axiosInstance } from "../utils/axiosInterceptor";

export const GetOrganizersListService = async () => {
    return await axiosInstance.post('organizer_list',);
}

export const CreateOrganizerService = async (data) => {
    return await axiosInstance.post('add_organizer', data);
}

export const GetOrganizerDropdown = async () => {
    return await axiosInstance.post('organizer_dropdown_list');
}
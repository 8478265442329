import Routing from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfigProvider } from 'antd';
import themeConfig from "./config/themeConfig";


function App() {
  return (
    <>
    <ConfigProvider theme={themeConfig}>
    <ToastContainer  
      position="bottom-center"
      autoClose={115000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme={"dark"}
      className={"toast-custom"}
      toastClassName={"toast-custom-container"}
    />
    <Routing />
    </ConfigProvider>
    </>
  );
}

export default App;

import React from 'react'
import { Outlet } from 'react-router-dom'
import TopBar from './TopBar';
// import { dreamcastLogo } from '../../assets/images';

export default function DashboardLayout() {
  return (
    <>
    <TopBar />
    <div className='content-wrapper'>
      <div className='container'>
        <Outlet />
      </div>
      {/* <div className='powered-by'>
        <p>Powered by</p>
        <img src={dreamcastLogo} alt="powered by dreamcast" />
      </div> */}
    </div>
    </>
  )
}

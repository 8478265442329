import { createContext, useState } from "react";

export const LoginFormContext = createContext();


export const LoginFormProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        mobile: "",
        otp: ""
    });

    return (
        <LoginFormContext.Provider value={{currentStep, setCurrentStep, formData, setFormData}}>
            {children}
        </LoginFormContext.Provider>
    );
}
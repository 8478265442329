import React, { useState } from 'react';
import { Layout, Dropdown, Button, Avatar } from 'antd';
import { UserOutlined, MenuOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from './Topbar.module.scss';
import {Link, useLocation} from 'react-router-dom';
import { dreamcastLogo } from '../../assets/images';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { clearAuthData } from '../../redux/actions/authActions';

const { Header } = Layout;

const TopBar = () => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const menuItems = [
    { key: 'dashboard', label: 'Dashboard', link: '/app/dashboard' },
    { key: 'events', label: 'Events', link: '/app/events' },
    { key: 'organizers', label: 'Organizers', link: '/app/organizers' },
    // { key: 'logout', label: 'Logout' },
  ];

  const profileMenu = {
    items: [
      {
        key: 'profile',
        label: 'Profile',
        icon: <UserOutlined />,
      },
      {
        key: 'logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        onClick: () => {
          dispatch(clearAuthData());
        }
      },
    ],
  };

  return (
    <Header className={styles.topbar}>
      <div className={styles.container}>
        <div className={styles.logo}>
          {/* <Activity /> */}
          <span><img src={dreamcastLogo} alt='logo' /></span>
          <nav className={styles.navigation}>
            {menuItems.map(item => (
              <Link key={item.key} to={`${item.link}`} className={pathname.includes(item.key) ? styles.active : ''}>
                {item.label}
              </Link>
            ))}
          </nav>
        </div>


        <div className={styles.mobileMenu}>
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          />
          
        </div>

        <div className={styles.profile}>
          <p className={styles.time}>{moment().format("HH:mm [GMT]Z")}</p>
          <Dropdown menu={profileMenu} placement="bottomRight">
            <Avatar style={{ cursor: 'pointer' }}>U</Avatar>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default TopBar;
import moment from 'moment';

export function formatEventDate(start_date, end_date) {
  const startDate = moment(start_date);
  const endDate = moment(end_date);

  if (startDate.isSame(endDate, 'day')) {
    // Same day
    if (startDate.isSame(endDate, 'minute')) {
      // Same date and time
      return startDate.format('DD MMM, YYYY');
    } else {
      // Same date, different time
      return `${startDate.format('DD MMM, YYYY')}, ${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`;
    }
  } else if (startDate.isSame(endDate, 'month')) {
    // Same month, different days
    return `${startDate.format('DD')}-${endDate.format('DD MMM, YYYY')}`;
  } else if (startDate.isSame(endDate, 'year')) {
    // Same year, different months
    return `${startDate.format('DD MMM')} - ${endDate.format('DD MMM, YYYY')}`;
  } else {
    // Different years
    return `${startDate.format('DD MMM, YYYY')} - ${endDate.format('DD MMM, YYYY')}`;
  }
}
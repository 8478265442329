import React, { useContext } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { loginFormIcon } from '../../assets/images'
import { LoginFormContext } from '../../context/LoginFormContext';
import { LoginService } from '../../apis/auth';
import { tostE } from '../../config/toastConfig';

export default function Welcome() {
    const {setCurrentStep, fromData, setFormData} = useContext(LoginFormContext);
    const formik = useFormik({
        initialValues: {
            mobile: ""
        },
        validationSchema: Yup.object({
            mobile: Yup.string().required("Mobile number is required").min(10, "Mobile number must be 10 digit")
        }),
        onSubmit: async (values) => {
            try {
                const res = await LoginService(values);
                if(res.status === 200 && res.data.success) {
                    setCurrentStep(2);
                    setFormData({
                        ...fromData,
                        mobile: values.mobile
                    })
                }else{
                    tostE(res.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        }
    });


    const { errors, touched, getFieldProps, handleSubmit } = formik;
    return (
        <>
            <span className='login-form-icon'>
                <img src={loginFormIcon} alt="loginFormIcon" />
            </span>
            <h4>Welcome</h4>
            <p>Please sign in or sign up below.</p>
            <FormikProvider value={formik}>
                <Form autoComplete='off' onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor="mobile">Mobile number</label>
                        <input
                            type="number"
                            {...getFieldProps('mobile')}
                            className={`form-control ${touched.mobile && errors.mobile && 'is-invalid'}`}
                            placeholder='Mobile number'
                        />
                        {touched.mobile && errors.mobile && (
                            <div className='invalid-feedback'>{errors.mobile}</div>
                        )}
                    </div>
                    <div className='form-action'>
                        <button type='submit' className='btn btn-primary w-100 mt-3'>Continue</button>
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}

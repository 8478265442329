import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Drawer, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { EVENT_NAME_REGEX, HASTAG_REGEX } from '../../../../config/constant';
import { AssignOrganizerService, GetEventDetailService, UpdateEventService, UpdateGalleryBtnStatusService } from '../../../../apis/events';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePickerInput, Label } from '../../../../components/ui';
import { GetOrganizerDropdown } from '../../../../apis/organizers';
import { tostE, tostS } from '../../../../config/toastConfig';

export default function EventOverview({ setTitle }) {
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visibilityLoading, setVisibilityLoading] = useState(false);
    const [showOrganizerModal, setShowOrganizerModal] = useState(false);
    const [isOrganizerUpdating, setIsOrganizerUpdating] = useState(false);
    const [organizerList, setOrganizerList] = useState([]);
    const [selectedOrganizer, setSelectedOrganizer] = useState(null);

    const getEventDetails = async () =>{
        setLoading(true);
        try {
            const res = await GetEventDetailService({id})
            if(res.status === 200 && res.data.success){
                const { event_name, hastag, start_date, end_date, gallery_btn } = res.data.event;
                setValues({
                    ...values,
                    event_name,
                    hastag,
                    start_date: dayjs(start_date),
                    end_date: dayjs(end_date),
                    gallery_btn
                });
                setTitle(event_name);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    }

    const getOrganizerDropdown = async () => {
        try {
            const res = await GetOrganizerDropdown();
            if (res.status === 200 && res.data.success) {
                setOrganizerList(res.data.organizer_dropdown_list);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getEventDetails();
        getOrganizerDropdown();
    }, []) // eslint-disable-line

    const CreateEventSchema = Yup.object().shape({
        event_name: Yup.string().required("Event name is required").matches(EVENT_NAME_REGEX, "Event name is invalid"),
        hastag: Yup.string().required("Hashtag is required").matches(HASTAG_REGEX, "Hashtag is invalid"),
        start_date: Yup.date().required("Start date is required"),
        end_date: Yup.date().required("End date is required"),
    });

    const formik = useFormik({
        initialValues: {
            event_name: "",
            hastag: "",
            start_date: "",
            end_date: "",
            gallery_btn: true
        },
        validationSchema: CreateEventSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            let body = {
                id: id,
                event_name: values.event_name,
                hastag: values.hastag,
                start_date: (values.start_date).format('YYYY-MM-DD'),
                end_date: (values.end_date).format('YYYY-MM-DD'),
                gallery_btn: values.gallery_btn ? 1 : 0
            }
            try {
                let res = await UpdateEventService(body);
                if (res.status === 200 && res.data.success) {
                    tostS(res.data.message);
                    setIsEditing(false);
                    resetForm();
                    getEventDetails();
                }
            } catch (error) {
                tostE(error.response.data.message);
            } finally {
                setSubmitting(false);
            }
        }
    });

    const { values, setValues, errors, touched, getFieldProps, handleSubmit, isSubmitting } = formik;


    const handleChangeGalleryVisibility = async (checked) => {
        console.log(checked);
        const body = {
            event_id : id,
            status : checked ? 1 : 0
        }
        setVisibilityLoading(true);
        try {
            const response = await UpdateGalleryBtnStatusService(body);
            if (response.status === 200 && response.data.success) {
                tostS(response.data.message);
                getEventDetails();
            }else{
                tostE(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setVisibilityLoading(false);
        }        
    }

    const handleChangeOrganizer = async (e) => {
        if(!selectedOrganizer){
            return;
        }
        const body = {
            event_id : id,
            user_id : selectedOrganizer
        }
        setIsOrganizerUpdating(true);
        try {
            const response = await AssignOrganizerService(body);
            if (response.status === 200 && response.data.success) {
                tostS(response.data.message);
                getEventDetails();
            }else{
                tostE(response.data.message);
            }
        } catch (error) {
            console.log(error);
        }finally{
            setSelectedOrganizer(null);
            setIsOrganizerUpdating(false);
            setShowOrganizerModal(false);
        }        
    }

    return (
        <>
            <div className='card custom-card'>
                <div className='event-info'>
                    <h4 className='text-black m-0'>{values.event_name}</h4>
                    <p>{values.hastag}</p>
                    <p className='pt-2'>Gallery Button Visibility</p>
                    <Switch 
                        size="small"
                        loading={visibilityLoading}
                        checked={values.gallery_btn} 
                        onChange={(checked) => { 
                            handleChangeGalleryVisibility(checked);
                        }} 
                    /> 
                    
                </div>
                <div className='d-flex justify-content-end'>
                    <Button onClick={() => { setIsEditing(true); }}>Edit Event</Button>
                </div>
            </div>
            <div className='host-details p-3'>
                <h5 className='m-0 text-black'>Organizer Details</h5>
                <p>Update Organizer Details</p>
                <div className='d-flex align-items-center mt-3'>
                    <Input readOnly className='w-25' placeholder='Organizer' addonAfter={<i onClick={() => { setShowOrganizerModal(true); }} className='fa-solid fa-pencil' />} />
                </div>
            </div>
            <Modal
                open={showOrganizerModal}
                onCancel={() => { setShowOrganizerModal(false); }}
                onOk={() => {handleChangeOrganizer();}}
                title="Update Organizer"
                okText="Assign Organizer"
                confirmLoading={isOrganizerUpdating}
            >
                <Select 
                    placeholder="Select Organizer"
                    style={{ width: '100%' }}
                    options={organizerList}
                    optionFilterProp="label"
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    onChange={(val) => { 
                        setSelectedOrganizer(val);
                    }}
                />
            </Modal>
            <Drawer
                closable
                distroyOnClose
                title="Edit Update"
                placement="right"
                open={isEditing}
                onClose={() => setIsEditing(false)}
                footer={(
                    <Space style={{ padding:"10px 0" }}>
                        <Button type="primary" onClick={() =>{handleSubmit();}} loading={isSubmitting}>Update</Button>
                        <Button onClick={() => { setIsEditing(false);}}>Cancel</Button>
                    </Space>
                )}
                loading={loading}
            >
                <FormikProvider value={formik}>
                    <Form layout="vertical" onFinish={handleSubmit}>
                        <Label
                            label='Event Name'
                            error={touched.event_name && errors.event_name}
                            helperText={touched.event_name && errors.event_name ? errors.event_name : ''}
                            required={true}
                        >
                            <Input
                                placeholder="Enter event name"
                                {...getFieldProps('event_name')}
                            />
                        </Label>
                        <Label
                            label='Hashtag'
                            error={touched.hastag && errors.hastag}
                            helperText={touched.hastag && errors.hastag ? errors.hastag : ''}
                            required={true}
                        >
                            <Input
                                placeholder="Enter hashtag(#hashtag)"
                                {...getFieldProps('hastag')}
                            />
                        </Label>
                        <Label
                            label='Start Date'
                            error={touched.start_date && errors.start_date}
                            helperText={touched.start_date && errors.start_date ? errors.start_date : ''}
                            required={true}
                        >
                            <DatePickerInput
                                placeholder="Select Start Date"
                                showNow={false}
                                value={values.start_date}
                                onChange={(date) => {
                                    setValues({ ...values, start_date: date })
                                }}
                                minDate={dayjs(new Date())}
                                fullWidth={true}
                            />
                        </Label>
                        <Label
                            label='End Date'
                            error={touched.end_date && errors.end_date}
                            helperText={touched.end_date && errors.end_date ? errors.end_date : ''}
                            required={true}
                        >
                            <DatePickerInput
                                placeholder="Select End Date"
                                showNow={false}
                                value={values.end_date}
                                onChange={(date) => {
                                    setValues({ ...values, end_date: date })
                                }}
                                minDate={dayjs(values.start_date)}
                                fullWidth={true}
                                disabled={values.start_date ? false : true}
                            />
                        </Label>
                        <Label
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 6 }}
                            label='Gallery Button'
                            error={touched.gallery_btn && errors.gallery_btn}
                            helperText={touched.gallery_btn && errors.gallery_btn ? errors.gallery_btn : ''}
                        >
                            <Switch
                                checked={values.gallery_btn}
                                onChange={(value) => {
                                    setValues({ ...values, gallery_btn: value })
                                }}
                                size='small'
                            />
                        </Label>
                    </Form>
                </FormikProvider>
            </Drawer>
        </>
    )
}

import React from 'react';
import { Button } from 'antd';
import { NoData } from '../../assets/images';

export default function NoEventComponent({handleButtonClick=()=>{}}) {
  return (
    <div className='error-page-container'>
            <img src={NoData} alt='no event' />
            <p className='error-page-text'>Oops! <br /> It seems there are no events here yet. Click below to create the first event!</p>
            <Button type='primary' onClick={() => handleButtonClick()} icon={<i class="fa-solid fa-plus"></i>}>Create Event</Button>
          </div>
  )
}

import React from 'react';
import { Modal } from 'antd';
const CustomModal = ({
    modalTitle="",
    modalContent=() => null,
    open=false,
    handleConfirm=()=>{},
    handleCancel=()=>{},
    children,
    ...props
}) => {
  
  const handleOk = () => {
    handleConfirm();
  };
  const handleCancelClick = () => {
    handleCancel();
  };
  return (
    <>
      <Modal
        open={open}
        maskClosable={false}
        title={modalTitle}
        onOk={handleOk}
        onCancel={handleCancelClick}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
        {...props}
      >
        {children}
      </Modal>
    </>
  );
};
export default CustomModal;
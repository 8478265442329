import { Skeleton } from 'antd'
import React from 'react'

export default function EventListSkeleton() {
  return (
    <>
    <Skeleton loading={true} active avatar />
    <Skeleton loading={true} active avatar />
    <Skeleton loading={true} active avatar />
    </>
  )
}

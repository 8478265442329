import React from 'react'
import { Outlet } from 'react-router-dom'
import { dreamcastLogo } from '../../assets/images'

export default function AuthLayout() {
  return (
    <section className='login-wrapper'>
      <Outlet />
      <div className='powered-by'>
        <p>Powered by</p>
        <img src={dreamcastLogo} alt="powered by dreamcast" />
      </div>
    </section>
  )
}

const themeConfig = {
    token: {
        colorPrimary: "#333537",
        colorInfo: "#333537"
    },
    components: {
        Button: {
            defaultShadow: false,
            primaryShadow: false,
            fontWeight: 600,
            defaultBg: "rgb(223,224,225)",
            defaultColor: "rgb(89,92,92)",
            defaultBorderColor: "rgb(223,224,225)"
        },
        Tabs: {
            colorText: "rgb(150,148,152)"
        },
        Table: {
            padding: 8
        }
    }
}

export default themeConfig;
const UiRender = ({color, text, icon}) =>{
    return (
        <span style={{color: color}} className="status-render">
            {icon && <i className={"mr-2 fa-solid " + icon}></i>}
            <p>{text}</p> 
        </span>
    )
}

export const EventStatusRender = ({value}) => {
    return (
        <UiRender color={'#37A353'} text={"Upcoming"} icon={'fa-circle'}/>
    )
}
import { axiosInstance } from "../utils/axiosInterceptor";

export const GetEventListService = async () => {
    return await axiosInstance.post('get_event_list');
}

export const CreateEventService = async (data) => {
    return await axiosInstance.post('add_event', data);
}   

export const UpdateEventService = async (data) => {
    return await axiosInstance.post('update_event', data);
}

export const GetEventDetailService = async (data) => {
    return await axiosInstance.post('get_event_by_id', data);
}

export const UpdateGalleryBtnStatusService = async (data) => {
    return await axiosInstance.post('update_gallery_btn_status', data);
}

export const GetEventUsersService = async (data) => {
    return await axiosInstance.post('user_list', data);
}

export const GetEventPhotosService = async (data) => {
    return await axiosInstance.post('photo_list', data);
}

export const AssignOrganizerService = async (data) => {
    return await axiosInstance.post('assign_event_to_user', data);
}
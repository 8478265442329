import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadCrumbs({ links = [{ name: 'Home', link: '/', icon: 'fa-house' }] }) {
    return (
        <>
            <ul className="breadcrumb">
                {links.map((link, index) => (
                    <li className="breadcrumb-item" key={index}>
                        <Link to={link.link}>
                            {link.icon && <i className={"mr-2 fa-solid " + link.icon}></i>}
                            <span>{link.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

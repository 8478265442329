import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import AuthLayout from '../layout/AuthLayout'
import LoginScreen from '../screens/auth/LoginScreen'

export default function AuthRouting() {
  return useRoutes([
    {
      path: 'auth',
      element: <AuthLayout />,
      children:[
        {
          path: 'login', 
          element: <LoginScreen />
        },
        { path: "*", element: <Navigate to="/auth/login" replace /> }
      ]
    },
    { path: "*", element: <Navigate to="/auth/login" replace /> }
  ])
}

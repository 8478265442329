import { Table } from 'antd'
import React from 'react'

export default function CustomTable({
    columns=[],
    data=[],
    ...props
}) {
  return (
    <Table 
        columns={columns} 
        dataSource={data} 
        {...props}
    />
  )
}

import React, { useContext } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { backIcon } from '../../assets/images';
import { LoginFormContext } from '../../context/LoginFormContext';
import OTPInput from 'react-otp-input';
import { VerifyOtpService } from '../../apis/auth';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../../redux/actions/authActions';
import { tostS } from '../../config/toastConfig';

export default function Authenticate() {
  const { formData, setCurrentStep } = useContext(LoginFormContext);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      mobile: formData.mobile,
      otp: ""
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required("Mobile number is required"),
      otp: Yup.string().required("OTP is required").min(6, "OTP must be 6 digits")
    }),
    onSubmit: async (values) => {
      try {
        const res = await VerifyOtpService(values);
        if(res.status === 200 && res.data.success) {
          console.log(res.data.data);
          const respData = res.data.data;
          dispatch(setAuthData(respData.token, respData.user_details))
        }else{
          tostS(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { values, setValues, errors, touched, handleSubmit } = formik;

  return (
    <>
      <span className='login-back-icon mb-3' onClick={() => { setCurrentStep(1) }}>
        <img src={backIcon} alt="loginFormIcon" />
      </span>
      <h4>Enter Code</h4>
      <p>Please enter the 6 digit code we sent to <br /><b>{formData.mobile}</b></p>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <div className='form-group'>
            <OTPInput
              value={values.otp}
              onChange={(otp) => {
                setValues({ ...values, otp })
              }}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle="form-control otp-input"
              shouldAutoFocus
            />
            {touched.otp && errors.otp && (
              <div className='invalid-feedback'>{errors.otp}</div>
            )}
          </div>
          <div className='d-flex justify-content-between mt-4'>
            <button className='btn btn-paste' type="submit">
              {/* <img src={pasteIcon} alt="paste" />  */}
              Submit Code</button>
            <button type="button" className='btn btn-link'>Resend Code</button>
          </div>
        </Form>
      </FormikProvider>
    </>
  )
}

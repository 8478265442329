import LoginForm from '../../components/auth/LoginForm';
import { LoginFormProvider } from '../../context/LoginFormContext';

export default function LoginScreen() {
  
  return (
    <LoginFormProvider>
      <LoginForm />
    </LoginFormProvider>
  )
}

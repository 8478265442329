import React from 'react'
import { BreadCrumbs } from '../../../components/ui'

export default function DashBoardPage() {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <BreadCrumbs />
    </div>
  )
}

import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import { EventOverview, EventPhotos, EventUsers } from './Details';

export default function ManageEvents() {
    const { id, tab } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");

    const tabsList = [
        {
          key: 'overview',
          label: 'Overview',
          children: <EventOverview setTitle={setTitle} />,
        },
        {
          key: 'users',
          label: 'Users',
          children: <EventUsers />,
        },
        {
          key: 'photos',
          label: 'Photos',
          children: <EventPhotos />,
        },
      ];

    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className='page-title'>{title}</h3>
                <Button 
                    iconPosition='end' 
                    icon={<i className="fa-solid fa-location-arrow"></i>}
                    onClick={() =>{navigate("/app/events")}}
                >
                    Event Page
                </Button>
            </div>
            <Tabs defaultActiveKey={tab} items={tabsList} onChange={key => navigate(`/app/events/manage/${id}/${key}`)} />
        </>
    )
}
